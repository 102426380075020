import * as React from 'react';
import { gql } from '@urql/core';
import queryString from 'query-string';
import { PageProps } from 'gatsby';
import { useDynamicProductPageQuery } from 'generated/graphql';
import ProductPage from 'templates/product';
import NotFoundPage from 'pages/404';

const DynamicProductPage = (props: PageProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const queryParams = queryString.parse(props.location.search);
  const id = typeof queryParams.id === 'string' ? parseInt(queryParams.id, 10) : 0;
  const [res] = useDynamicProductPageQuery({
    variables: { id },
  });

  if (res.error) {
    return <div>{res.error.message}</div>;
  }

  if (res.fetching || !res.data) {
    return <div>Загрузка...</div>;
  }

  if (!res.data.product) {
    return <NotFoundPage />;
  }

  return <ProductPage {...props} data={res.data} />;
};

gql`
  query DynamicProductPageQuery($id: Int!) {
    ...ProductPage_data
  }
`;

export default DynamicProductPage;
